@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelveticaNeue';
  src: url(./fonts/Helvetica-Neue.ttf);
}

:root {
  --primary-color: #1C3B7D;
  --primary-dark: #1C3B7D;
  --secondary-color: #578cff;
  --secondary-dark:#1C3B7D;
  --overlay: rgba(95, 95, 95, 0.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.blocks {
  max-width: 1400px;
  margin-bottom: 20rem;
  background-color: #ffffff;
  margin: auto;
  padding: 8rem 0.25rem 0rem 0.25rem;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-color);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}