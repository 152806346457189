.hero {
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
    
}

.hero:before {
    content: '';
    background: url('./images/bg_aafrunz_03.webp') no-repeat center center/cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 1rem;
}

.hero .glass-panel {
    background-color: rgba(0, 153, 255, 0.12);
}

.hero .content p:first-child {
    font-style: italic;
    font-size: .9rem;
}

.hero .content p:nth-child(2) {
    font-size: 1.9rem;
}

.hero .content p:nth-child(3) {
    font-size: 4rem;
    font-weight: 700;
}